.placeholder-table {
  border-collapse: collapse;
}

.placeholder-td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 0 8px;
}

.placeholder-th {
  background-color: rgb(229 231 235);
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.placeholder-tr tr:nth-child(even) {
  /*background-color: rgb(229 231 235);*/
}
