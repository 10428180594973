.k-textbox-container > span.k-label {
    font-weight: bold;
    font-size: 16px;

}

.k-dropdown span.k-dropdown-wrap.k-state-focused, .k-dropdowntree span.k-dropdown-wrap.k-state-focused {
    box-shadow: unset;


}

.k-dropdown span.k-dropdown-wrap {
    border-color: unset;
}